import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <App />
  </React.StrictMode>
);
const title = import.meta.env[`VITE_REACT_APP_TITLE_${import.meta.env.MODE}`]
const key = import.meta.env[`VITE_REACT_APP_KEY_SENTRY_${title}`]
const envsentry = import.meta.env[`VITE_REACT_APP_SENTRY_ENV_${title}`]
Sentry.init({
  release: `RDO_${title}_FE_CONTAINER_v` + packageJson.version,
  dsn: key,
  environment: envsentry,
  initialScope: {
    tags: {
      RDO_VERSION: packageJson.version,
    }
  },
  ignoreErrors: [
    "Unexpected token '<'",
    "Loading chunk ",
    "Failed to fetch",
    "undefined is not an object (evaluating 'a.N')",
    "Load failed",
    "network error",
    "Attempt to use history.replaceState() more than 100 times per 10 seconds"
  ]
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
